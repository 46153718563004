
export const fieldsForm = {
    firstName: {
        elementType: 'input',
        controlId: 'registerFirstName',
        placeholder: '',
        label: 'First Name *',
        type: 'text'
    },
    lastName: {
        elementType: 'input',
        controlId: 'registerLastName',
        placeholder: '',
        label: 'Last Name *',
        type: 'text'
    },
    email: {
        elementType: 'input',
        controlId: 'registerEmail',
        placeholder: '',
        label: 'Email *',
        type: 'email',
        change: true,
        blur: true,
        handleBlur: true,
    },
    password: {
        elementType: 'input',
        controlId: 'registerPassword',
        placeholder: '',
        label: 'CREATE A PASSWORD *',
        type: 'password'
    },
    /* verifyPassword: {
         elementType: 'input',
         controlId: 'registerVerifyPassword',
         placeholder: 'Verify Password',
         label: 'Verify Password *',
         type: 'password'
     }, */

    invitationCode: {
        elementType: 'input',
        controlId: 'registerInvitationCode',
        placeholder: '',
        label: 'Invitation Code *',
        type: 'text'
    },
    institute: {
        elementType: 'input',
        controlId: 'registerInstitute',
        placeholder: '',
        label: 'Institution',
        type: 'text'
    },
    approved: {
        elementType: 'input',
        controlId: 'registerApproved',
        className: 'approve',
        placeholder: 'I agree to receive email reminders about the screening and updates about the film.',
        label: 'I agree to receive email reminders about the screening and updates about the film.',
        type: 'checkbox'
    },
    confirmEmailPassword: {
        elementType: 'input',
        controlId: 'registerConfirmEmailPassword',
        placeholder: 'Confirm Email Password ',
        label: 'Confirm Email Password *',
        type: 'text'
    },

}