import React, { useState, useEffect } from 'react';
import FormGroup from 'components/FormGroup';
import { useLazyQuery } from "@apollo/client";
import { useFormik } from 'formik';
import _mapValues from 'lodash/mapValues';
import {DisplayMsg} from '../config';


export default function useForm({
    fieldsForm,
    // schema of fields validation
    schema,
    // dont use the default submit handler
    onSubmit,
    loadQuery,
    // name of query in order to pull the data
    loadQueryReply,
}) {

    const [fieldState, setFieldState] = useState({ ...fieldsForm })
    const [formsMessage, setFormsMessage] = useState({
        isError: 0,
        text: ""
      });
    
    const [disableField, setDisableField] = useState(false);


    const formik = useFormik({
        initialValues: _mapValues(fieldState, 'value'),
        validationSchema: schema,
        enableReinitialize: true,
        onSubmit: onSubmit,
        validateOnBlur: false,
        validateOnChange: false
    });

    
    useEffect(() => {
        if (loadQuery) {
            loadData();
        }
    }, [])
    if (loadQuery) {
        const [loadData, { data: formData, error: loadError }] = useLazyQuery(loadQuery)
        useEffect(() => {
            if (formData && formData[loadQueryReply]) {
                handleLoadData(formData[loadQueryReply])
            }
        }, [formData, loadError])
    }


    const renderElementsForm = () => {
        const formElementsArr = []
        if (fieldState) {
            for (let key in fieldState) {
                formElementsArr.push({
                    id: key,
                    config: fieldState[key]
                })
            }
        }
        return formElementsArr
    }

    const renderElement = (formElement, children) => {
        let Element = (
            <FormGroup
                config={formElement.config.controlConfig}
                key={formElement.id}
                name={formElement.id}
                label={formElement.config.label}
                defaultValue={formElement.config.defaultValue}
                elementType={formElement.config.elementType}
                controlId={formElement.config.controlId}
                placeholder={formElement.config.placeholder}
                type={formElement.config.type}
                value={formik.values[formElement.id]}
                changed={formik.handleChange}
                blured={formElement.config.handleBlur? handleBlur : null}
                disable={disableField}
                children={children}
                errorMessage={formik.errors[formElement.id]}
                aria-label={formElement.config.label}
                aria-describedby={formElement.config.label}
                className={formElement.config?.className}
            />
        )
        return Element;
    }

    const handleLoadData = data => {

        const loadForm = { ...fieldState }
        if (data) {
            for (let formElement in fieldState) {
                loadForm[formElement] = {
                    ...fieldState[formElement],
                    value: (data[formElement] || data[formElement] === false) ? data[formElement] : loadForm[formElement].value,
                }
            }
        }
        setFieldState(loadForm)
    }

   // handleBlur function
    const handleBlur= (e) => {
        let email = e.target.value;
            // check if this user exist
            console.log("handleblur: "+e.target.value);
            const requestMetadata = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: 'include',
                body: JSON.stringify({ "email": email }),
            };
            fetch(`${PROFILE_URL}/auth/isUserEmail`, requestMetadata)
                .then(res => res.json())
                .then(result => {
                    if (result?.msg) {
                        console.log("email exist");
                        // show message
                        if(result.type && result.type == "e"){
                            setFormsMessage({ isError: 1, text: DisplayMsg.emailExistGoToEmailLogin })
                        }else{
                            setFormsMessage({ isError: 1, text: DisplayMsg.emailExistGoToLogin })
                        }
                        // disable field
                        //setDisableField(true);

                    }
                    else{
                        setFormsMessage({ isError: 0, text: "" })
                       //console.log("email does not exist");
                    }
                }, error => {
                })
      }

    return { formik, renderElementsForm, renderElement, handleLoadData , formsMessage,setFormsMessage}

}

