
export const fieldsForm = {
    email: {
        elementType: 'input',
        controlId: 'registerEmail',
        label: 'Email *',
        className: 'half'
    },
    invitationCode: {
        elementType: 'input',
        controlId: 'registerInvitationCode',
        label: 'invitation Code *',
        className: 'half'
    },
    instituteName: {
        elementType: 'input',
        controlId: 'registerInstitute',
        placeholder: '',
        label: 'Institution Name *',
        type: 'text'
    },
    instituteType: {
        elementType: 'select',
        controlId: 'registerInstituteType',
        label: 'Institution Type *'
    },
    instituteWebsite: {
        elementType: 'input',
        controlId: 'registerInstituteWebsite',
        label: 'Institution Website *',
        type: 'text',
        defaultValue: 'https://'
    },
    jobTitle: {
        elementType: 'select',
        controlId: 'registerJobTitle',
        label: 'Job Title *',
    },
    terms: {
        elementType: 'input',
        controlId: 'registerApproved',
        className: 'approve',
        type: 'checkbox'
    },
    
    
}