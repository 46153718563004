

import React, { useEffect, useMemo, useState } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { fieldsForm, schema } from './fields'
import useForm from 'hooks/useForm';
import { FORGOT_PASSWORD } from 'schemas/profile';
import { useMutation } from "@apollo/client";

import './style.scss';
import ErrorMessage from 'components/ErrorMessage';



export default function ForgotPassword() {

    const [message, setMessage] = useState({
        isError: 0,
        text: ""
      });

    const {
        formik,
        renderElementsForm,
        renderElement,
        handleLoadData
    } = useForm({
        fieldsForm,
        schema,
        onSubmit: handleSubmit
    })

    const formElements = useMemo(() => renderElementsForm(), [fieldsForm]);
    const [forgotPassword, { data, error }] = useMutation(FORGOT_PASSWORD);

    useEffect(() => {
        if (data) {
            setMessage({ isError: 0, text: data.forgotPassword?.body?.msg });
            console.log({message});
        }
        if (error) {
            setMessage({ isError: 1, text: error.graphQLErrors[0]?.extensions?.response?.body?.error });
            console.log({message});
        }
    }, [data, error]);

    function handleSubmit(values) {
        forgotPassword({ variables: { ...values } });
        renderElement();
    }


    return (
        <div className="lin-forgot-password-form lin-login-form">
            <Form onSubmit={formik.handleSubmit}>
            <Row>
                {message.text && <ErrorMessage isError={message.isError}>{message.text}</ErrorMessage>}
            </Row>
                <Row>
                    <Col className="lin-wrapper">
                        {renderElement(formElements[0])}

                    </Col>
                </Row>
                <Button as="input" type="submit" value={"Get New Password"} />{' '}
            </Form>
        </div >

    )
}