

import React, { useEffect, useMemo, useState,  useRef} from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { fieldsForm, schema } from './fields'
import { useMutation, useLazyQuery, useQuery } from "@apollo/client";
import useForm from 'hooks/useForm';
import { REDIRECT_TO_EVENT, GET_ENUMS, UPDATE_USER } from 'schemas/profile';
import ErrorMessage from 'components/ErrorMessage';
import useLogin from 'hooks/useLogin';
import * as yup from 'yup';
import Cookies from 'js-cookie';
import urlParse from 'url-parse';
import EmailLoginForm from 'widgets/EmailLoginForm/index.js';

import './style.scss';


export default function ExtendedLoginForm(props) {
    
    const hasInfo = useMemo(()=> {
        let value =  Cookies.get('hasInfo');
        return (value == 1)? true:false;
    }, []); 

    const [loginValues, setLoginValues] = React.useState({
        email: '',
        invitationCode: '',
        instituteName: '',
        instituteType: '',
        instituteWebsite: '',
        jobTitle: '',
        terms: false
        
    });


    // Add method
    yup.addMethod(yup.string, 'removeEmptySpaces', function () {
        return this.transform((value) => value.trim());
    });


    const schema = yup.object().shape({
        email: yup.string().email().required("This field is required"),
        invitationCode: yup.string().removeEmptySpaces().required("This field is required"),
        terms: yup.boolean().oneOf([true],"This field is required").required("This field is required"),
        instituteName: yup.string().required("This field is required"),
        instituteType: yup.string().required("This field is required"),
        instituteWebsite: yup.string().url("Enter a valid url").required("This field is required"),
        jobTitle: yup.string().required("This field is required"),
    });

   
    const [getEnumsVariables, {data: dataInfo, error: errorInfo}]  = useLazyQuery(GET_ENUMS);
    const [JobTitle, setJobTitle] = useState([]);
    const [InstitutionType, setInstitutionType] = useState([]);
    
    const {
        formik,
        renderElementsForm,
        renderElement,
        formsMessage
    } = useForm({
        fieldsForm,
        schema,
        onSubmit: handleSubmit
    })
    const [message, setMessage] = useState({
        isError: 0,
        text: ""
    });
    
    const formElements = useMemo(() => renderElementsForm(), [fieldsForm]);
    const { login, loggedIn, status } = useLogin();
    const [redirectToEvent, { data, error }] = useMutation(REDIRECT_TO_EVENT);
    const [callUpdateUser, {dataUser, errorUser}] = useMutation(UPDATE_USER, {
        onCompleted: (dataUser) => {
          if(dataUser?.updateUser?.body?.hasError == 0){
            Cookies.set('hasInfo', 1); 
            let values = loginValues;
            values["type"] = "email";
            login(values);
          }else{
            setMessage({ isError: 1, text:dataUser?.updateUser?.body?.msg});
          }
        },
        onError: (err) => {
            console.info("Error updte user: "+JSON.stringify(err));
        }
      });

    useEffect(() => {
        getEnumsVariables();
        if(props?.invitecode){
            fieldsForm.invitationCode.placeholder = props.invitecode;
            fieldsForm.invitationCode.value = props.invitecode;
            fieldsForm.invitationCode.className = fieldsForm.invitationCode.className+" disable";
        }
    }, []);
    useEffect(() => {
        if (dataInfo) {
          setJobTitle(dataInfo.getEnums?.body?.jobTitle);
          setInstitutionType(dataInfo.getEnums?.body?.institutionType);
        }
        if(errorInfo){
            console.info( "GetEnum failed: "+JSON.stringify(errorInfo))
        }
      }, [dataInfo, errorInfo]);

    useEffect(() => {
        if (data) {
            window.location.replace(`${WORDPRESS_URL}?p=${data.redirectToEvent?.body.eventId}`)
        }
    }, [data]);

   
    useEffect(() => {
        setMessage(status);

        if (loggedIn) {
            redirectToEvent({ variables: { fields: { ...loginValues } } });
        }
    }, [loggedIn, status]);

    function handleSubmit(values) {
       
        values.invitationCode = values.invitationCode.trim();
        let website = values.instituteWebsite;

        const parsedUrl = urlParse(values.instituteWebsite);
        values.instituteWebsite = parsedUrl.hostname.replace(/^www\./, '');
        setLoginValues(values);
        values["type"] = "email";
        
        callUpdateUser({ variables: { fields: { ...values } } }); 
        values.instituteWebsite = website;
        formsMessage.isError= 0;
        formsMessage.text= "";
    }

    function renderOptions(options) {
        return (
            <>
                <option value="empty"></option>
                {options.map(function(data, index){
                    return (
                        <option value={data} key={index}>{data}</option>
                    )
                })}
            </>
        )
    }
   
    return (
        
        <div className="lin-ex-login-form">
            { hasInfo? (<EmailLoginForm invitecode={props?.invitecode} />):(
            <Form onSubmit={formik.handleSubmit}>
                <Row>
                    {message.text && <ErrorMessage isError={message.isError}>{message.text}</ErrorMessage>}
                </Row>
                
                <Row>
                    <Col className="lin-wrapper">
                        {renderElement(formElements[0])}
                        {renderElement(formElements[1])}
                    </Col>
                    <Col className="lin-wrapper">
                        {renderElement(formElements[2])}
                        {renderElement(formElements[3], renderOptions(InstitutionType))}
                        {renderElement(formElements[4])}
                        {renderElement(formElements[5], renderOptions(JobTitle))}
                        
                    </Col>
                    <div className="terms">
                            {renderElement(formElements[6])}
                            <p>I agree with Film Platform's <a target='_blank' href='https://filmplatform.zendesk.com/hc/en-us/articles/360017079377-Film-Platform-Privacy-Policy'>Privacy Policy</a> and <a target='_blank' href='https://filmplatform.zendesk.com/hc/en-us/articles/360017079977-Terms-of-Service'>Terms of Service.</a>
                            </p>
                    </div>
                </Row>
                
                <Button as="input" type="submit" value={"login"} />{' '}
            </Form>
            )}
        </div >
   
    )
    
}