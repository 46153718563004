import React, { useEffect } from 'react';
import './style.scss';

function ErrorMessage({ isError, children }) {
  return (
    <div className={`lin-error-message ${isError ? "" : "success"}`} dangerouslySetInnerHTML={{ __html: children }} />
  )
}

export default ErrorMessage;
//color: #060;