import React, { useEffect, useMemo, useState, useRef } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { fieldsForm } from './fields'
import { useMutation, useLazyQuery } from "@apollo/client";
import useForm from 'hooks/useForm';
import { REGISTER, GET_INVITATION_CODE } from 'schemas/profile';
import './style.scss';
import * as yup from 'yup';
import ErrorMessage from 'components/ErrorMessage';
import { REDIRECT_TO_EVENT } from 'schemas/profile';
import useLogin from 'hooks/useLogin';


export default function EmailRegistrationForm(props) {
  const formRef = useRef();

  const [message, setMessage] = useState({
    isError: 0,
    text: ""
  });

  const [currectInvitationCode, setCurrectInvitationCode] = useState();

  // Add method
  yup.addMethod(yup.string, 'removeEmptySpaces', function () {
    return this.transform((value) => value.trim());
  });

  const schema = yup.object().shape({
    firstName: yup.string().required("This field is required"),
    lastName: yup.string().required("This field is required"),
    email: yup.string().email().required("This field is required"),
    //password: yup.string().required("This field is required").matches(
    //  /.*[^ ].*/,
    //  "Cannot Contain Only Spaces"
    //), 
    invitationCode: yup.string().removeEmptySpaces().required("This field is required").oneOf([currectInvitationCode], "This code is not valid!"),
    //institute: yup.string().required("This field is required")
    //approved: yup.array().required("This field is required"),
    verifyEmail: yup.string().required("This field is required")
    .oneOf([yup.ref('email'), null], 'Emails must match')
  });
  const {
    formik,
    renderElementsForm,
    renderElement,
    formsMessage
  } = useForm({
    fieldsForm,
    schema,
    onSubmit: handleSubmit,

  })


  const formElements = useMemo(() => renderElementsForm(), [fieldsForm]);
  
  const [register, { data, error }] = useMutation(REGISTER);
  const [redirectToEvent, { data: redirectData, error: redirectError }] = useMutation(REDIRECT_TO_EVENT);

  const [getInvitationCode, { data: dataInfo, error: errorInfo }] = useLazyQuery(GET_INVITATION_CODE);
  const { login, loggedIn, status } = useLogin();
  const [loginValues, setLoginValues] = useState({
    email: '',
    password: '',
    invitationCode: '',
    type: 'email'
  });

  const [disableSubmit, setDisableSubmit] = useState(false);

  useEffect(() => {
    if (data) {
      setMessage({ isError: 0, text: data.register?.body?.msg });
      login(loginValues);
    }
    if (error) {
      setMessage({ isError: 1, text: error.graphQLErrors[0]?.extensions?.response?.body?.msg });
    }
  }, [data, error]);

  useEffect(() => {
    if (loggedIn) {
      redirectToEvent({ variables: { fields: { ...loginValues } } });
    }
  }, [loggedIn, status]);

  useEffect(() => {
    if (formsMessage ) {
      setMessage(formsMessage);
      //setDisableSubmit(true);
    }
  }, [formsMessage]);

  useEffect(() => {
    if (redirectData) {
      setTimeout(() => {
        window.location.replace(`${WORDPRESS_URL}?p=${redirectData.redirectToEvent?.body.eventId}`)
      }, 4000);
    }
  }, [redirectData]);

  useEffect(() => {
    
    getInvitationCode({ variables: { eventId: props?.event_id } });
    //console.log('invitation code:', dataInfo.getInvitationCode?.body?.msg);
  }, []);

  useEffect(() => {
    if (dataInfo) {
      //console.log(atob(dataInfo.getInvitationCode?.body?.msg))
      setCurrectInvitationCode(atob(dataInfo.getInvitationCode?.body?.msg));
    }
  }, [dataInfo, errorInfo]);


  function handleSubmit(values) {
    console.info("inside handleSubmit");
    // if formsMessage is not empty then we do not process the submit because this
    // means that the email is recognized and the user needs to login through login widget
    if (formsMessage && formsMessage.text != "") {
      return;
    }
    values["status"] = 0
    values["eventId"] = props?.event_id;
    values["maxCodes"] = props?.max_codes || 10;
    values["eventName"] = props?.event_name || "";
    values["eventUrl"] = props?.event_url || "";
    values["filmName"] = props?.film_name || "";
    values["startDate"] = props?.start_date || "";
    values["isImpact"] = props?.is_impact || "false";
    values.invitationCode = values.invitationCode.trim();
    
    register({ variables: { fields: { ...values } } });
    setLoginValues({ email: values.email, password: null, invitationCode: values.invitationCode });
  }

  useEffect(() => {
    let firstErrorElement = formRef.current.querySelector('.lin-error-message');

    if (firstErrorElement) {
      firstErrorElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }
  });


  
  return (
    <div className="lin-registration-form">
      <Form onSubmit={formik.handleSubmit} ref={formRef}>
        <Row>
          {message.text && <ErrorMessage isError={message.isError}>{message.text}</ErrorMessage>}
        </Row>
        <Row>
          <Col className="lin-wrapper">
            {renderElement(formElements[0])}
            {renderElement(formElements[1])}
            {renderElement(formElements[2])}
            {renderElement(formElements[3])}
            {renderElement(formElements[4])}
            {renderElement(formElements[5])}
            {renderElement(formElements[6])}
          </Col>
        </Row>
        <div className="terms">
          <p>By clicking 'REGISTER', I agree to Film Platform's <a target='_blank' href='https://filmplatform.zendesk.com/hc/en-us/articles/360017079377-Film-Platform-Privacy-Policy'>Privacy Policy</a> and <a target='_blank' href='https://filmplatform.zendesk.com/hc/en-us/articles/360017079977-Terms-of-Service'>Terms of Service.</a>
          </p>
        </div>
        <Button as="input" type="submit" disabled={disableSubmit} value={"Register"} />
      </Form>
    </div>
  )
}
