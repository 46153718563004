import React, { useState } from 'react';
import { GlobalState } from "contexts/GlobalState";
import './style.scss';
import { useMutation } from "@apollo/client";
import useLogout from 'hooks/useLogout';




export default function EventHeaderButton(props) {

    const type= (props?.type)? props?.type : "p";
    const url = (props?.url != null)? props?.url :"event-login";
    const loginUrl = (props?.extended == true)? url : url+"/?t="+type;
    const [loginClassName, setLoginClassName] = useState("login-img");
    const [menuClassName, setMenuClassName] = useState("topMyaccount event");
    const [accountClassName, setAccountClassName] = useState("account-user-name");
    const [globalUser] = GlobalState("user");
    const { logout} = useLogout();

    const menuItems = [
        {
         title: "Logout",
         onClick: logout,
         class: "logout no-border",
        }
       ];
    
    const Navbar = () => {
        return (
         <div className={menuClassName}>
          <div className="sub-menu">
            <div className="sub-menu-links">
                {menuItems.map((menu, index) => {
                    return (
                    <div className={menu.class} key={index}>
                    <a href="/#" onClick={menu.onClick}>{menu.title}</a>
                    </div>
                    );
                })}
            </div>
          </div>
         </div>
        );
       };

       // this function handles the sub menu open and close
    function handleClick(e){
        //console.log("inside handleClick: "+ e.target.className);
        
        if(e.target.className.includes('open')){
            setLoginClassName("login-img");
            setMenuClassName("topMyaccount event");
            setAccountClassName("account-user-name");
        }else{
            setLoginClassName("login-img open");
            setMenuClassName("topMyaccount open event");
            setAccountClassName("account-user-name open");
        }
        
    } 

    return (
        !globalUser.uid
            ? <a className="login-button" href={loginUrl}>Event Login</a>
            : (
                <>
                <div className="my-account-menu" >
                    <div className="my-account-name">
                        <div className={accountClassName} onClick={handleClick}>{globalUser.firstName}</div>
                        <div className={loginClassName} onClick={handleClick}></div>
                    </div>
                </div>
                <Navbar />
                </>
                
            )

    )
}

