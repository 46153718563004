import * as yup from 'yup';

export const schema = yup.object().shape({
    email: yup.string().email().required("This field is required"),
});

export const fieldsForm = {
    email: {
        elementType: 'input',
        controlId: 'registerEmail',
        placeholder: 'Email',
    },

}