import React from 'react';

export default function useLogin() {
  const [loggedIn, setLoggedIn] = React.useState(false);
  const [status, setStatus] = React.useState({
    isError: 0,
    text: ''
  });

  const login = (values) => {
    const requestMetadata = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: 'include',
      body: JSON.stringify(values),
    };

    let hasError = 0;

    fetch(`${PROFILE_URL}/auth/login`, requestMetadata)
      .then(function (res) {
        if (res.status == 400) {
          hasError = 1;
        }

        return res.json();
      })
      .then(result => {
        if (result?.msg) {
          if (hasError == 1) {
            setStatus({ isError: 1, text: result?.msg });
          } else {
            setStatus({ isError: 0, text: '' });
            result.access_token && localStorage.setItem("token", result.access_token);
            setLoggedIn(true);
          }
        }
      }, error => {
        console.log('Login error: ', error);
      })
  }

  return {
    login,
    loggedIn,
    status
  }
}